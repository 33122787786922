const GLOSSARY = [
  {
    name: 'Aspect-based sentiment analysis',
    description:
      'Aspect-based sentiment analysis is one of the three levels of sentiment analysis–the others being Document-based and Topic-based. These algorithms work together with named entity recognition (NER), natural language processing (NLP)…',
    article: 'internal-glossary-aspect-based-sentiment-analysis',
    path: '/social-media-tools/glossary/aspect-based-sentiment-analysis',
  },
  {
    name: 'Artificial Intelligence',
    description:
      'Artificial intelligence (AI) is a field of computer science that mimics the problem-solving and decision making capabilities of the human mind. AI has helped make significant advancements in areas such…',
    article: 'internal-glossary-artificial-intelligence',
    path: '/social-media-tools/glossary/artificial-intelligence',
  },
  {
    name: 'Algorithm',
    description:
      'An algorithm is a sequence of steps used to solve specific problems. Algorithms are often scientific or mathematical equations. In recent years, software engineers, data scientists and content strategists have…',
    article: 'internal-glossary-algorithm',
    path: '/social-media-tools/glossary/algorithm',
  },
  {
    name: 'BeReal',
    description:
      'BeReal is a photo-sharing application that allows users to post one photo per day to show their followers what they are doing in real-time. The BeReal app focuses on authenticity…',
    article: 'internal-glossary-bereal',
    path: '/social-media-tools/glossary/bereal',
  },
  {
    name: 'Brand authenticity',
    description:
      "In the age of social media, brands can't hide anything from their customers. Your audiences can find out everything they need to know about your business from other consumers. As…",
    article: 'internal-glossary-brand-authenticity',
    path: '/social-media-tools/glossary/brand-authenticity',
  },
  {
    name: 'Brand style guide',
    description:
      "A brand style guide is a crucial document that dictates how a business presents itself to the world. This reference tool promotes consistency in an organization's social media, offline and…",
    article: 'internal-glossary-brand-style-guide',
    path: '/social-media-tools/glossary/brand-style-guide',
  },
  {
    name: 'Business objective',
    description:
      'Business objectives are the specific and measurable results companies hope to maintain as their organization grows. Entrepreneurs and business leaders must track performance in every part of their business to…',
    article: 'internal-glossary-business-objective',
    path: '/social-media-tools/glossary/business-objective',
  },
  {
    name: 'Brand advocate',
    description:
      'A brand advocate is someone who elevates your brand through word of mouth marketing. Brand advocates leave positive reviews about your product. They also refer new customers and create content…',
    article: 'internal-glossary-brand-advocate',
    path: '/social-media-tools/glossary/brand-advocate',
  },
  {
    name: 'Competitive intelligence',
    description:
      'Competitive intelligence is the process of gathering and reviewing information related to a competitor’s strengths and weaknesses. A journey into competitive intelligence can help give you a better understanding of…',
    article: 'internal-glossary-competitive-intelligence',
    path: '/social-media-tools/glossary/competitive-intelligence',
  },
  {
    name: 'Chatbot',
    description:
      'Chatbots, or messenger bots, are pieces of software designed to respond to messages sent on social media, websites and more. Chatbots can follow pre-set conversation trees to answer customer questions.…',
    article: 'internal-glossary-chatbot',
    path: '/social-media-tools/glossary/chatbot',
  },
  {
    name: 'Character count',
    description:
      'On social media, the length of a post often affects the impact of your content. Following the character count rules of each platform means that your messages display fully on…',
    article: 'internal-glossary-character-count',
    path: '/social-media-tools/glossary/character-count',
  },
  {
    name: 'CPM',
    description:
      'CPM (cost per mille) is a paid advertising option where companies pay a price for every 1,000 impressions an ad receives. An "impression" refers to when someone sees a campaign…',
    article: 'internal-glossary-cpm',
    path: '/social-media-tools/glossary/cpm',
  },
  {
    name: 'Carousel ads',
    description:
      'Carousel ads are a kind of advertising format that combines multiple videos or images into a single ad. Carousel ads are most popular on Instagram and Facebook, where you can…',
    article: 'internal-glossary-carousel-ads',
    path: '/social-media-tools/glossary/carousel-ads',
  },
  {
    name: 'Creative Commons',
    description:
      'Images are an important aspect of content marketing. More than 60% of consumers consider images the most engaging types of in-feed social content. When many marketers start searching for images…',
    article: 'internal-glossary-creative-commons',
    path: '/social-media-tools/glossary/creative-commons',
  },
  {
    name: 'Clickbait',
    description:
      'Clickbait is a form of content designed to gather clicks on the search engine result pages. With clickbait, companies attempt to generate traffic on their blogs or websites, often relying…',
    article: 'internal-glossary-clickbait',
    path: '/social-media-tools/glossary/clickbait',
  },
  {
    name: 'Content curation',
    description:
      'Content curation is the act of finding and sharing excellent, relevant content to your online followers. With content curation, companies deliver a wide variety of content to their customers, keeping…',
    article: 'internal-glossary-content-curation',
    path: '/social-media-tools/glossary/content-curation',
  },
  {
    name: 'Click through rate (CTR)',
    description:
      'Click through rate is a marketing metric that measures how often people click on a link, ad or email. Many factors affect click-through rate (CTR), including imagery, keywords, call-to-action copy…',
    article: 'internal-glossary-click-through-rate-ctr',
    path: '/social-media-tools/glossary/click-through-rate-ctr',
  },
  {
    name: 'Cover photo',
    description:
      'A cover photo is an image on a Facebook profile. Unlike a profile picture, cover photos are large banner graphic that introduces visitors to an individual or brand. Facebook cover…',
    article: 'internal-glossary-cover-photo',
    path: '/social-media-tools/glossary/cover-photo',
  },
  {
    name: 'Call to action (CTA)',
    description:
      'A call to action or "CTA" is a prompt on your website that asks users to take a specific action. Most call to action copy is written as an imperative,…',
    article: 'internal-glossary-call-to-action-cta',
    path: '/social-media-tools/glossary/call-to-action-cta',
  },
  {
    name: 'Crowdsourcing',
    description:
      'Crowdsourcing is the act of collecting services, ideas or content through the contributions of a large group of people. Typically, the "crowd" in crowdsourcing is a third-party unrelated to the…',
    article: 'internal-glossary-crowdsourcing',
    path: '/social-media-tools/glossary/crowdsourcing',
  },
  {
    name: 'CPC (Cost per click)',
    description:
      'Cost per click (CPC) is a paid advertising term where an advertiser pays a cost to a publisher for every click on an ad. CPC is also called pay per…',
    article: 'internal-glossary-cpc-cost-per-click',
    path: '/social-media-tools/glossary/cpc-cost-per-click',
  },
  {
    name: 'Conversion tracking',
    description:
      'Conversion tracking is when a brand monitors the actions consumers take toward the completion of a business goal. These actions can include signing up for a newsletter, downloading a content…',
    article: 'internal-glossary-conversion-tracking',
    path: '/social-media-tools/glossary/conversion-tracking',
  },
  {
    name: 'Co-marketing',
    description:
      'Co-marketing is a marketing strategy where brands or organizations partner together to expand their reach. Typically, the companies working with each other have some similarities, such as being in the…',
    article: 'internal-glossary-co-marketing',
    path: '/social-media-tools/glossary/co-marketing',
  },
  {
    name: 'Conversational commerce',
    description:
      'Conversational commerce is the empowerment of consumers to make shopping decisions, purchases and transactions through messaging apps and other conversational technology. Conversational services and technology have transformed the way people…',
    article: 'internal-glossary-conversational-commerce',
    path: '/social-media-tools/glossary/conversational-commerce',
  },
  {
    name: 'Content marketing',
    description:
      'Content marketing is a type of marketing where brands plan, create, promote and analyze content to meet strategic goals. Brands use content to attract, engage and interest users through various…',
    article: 'internal-glossary-content-marketing',
    path: '/social-media-tools/glossary/content-marketing',
  },
  {
    name: 'Doomscrolling',
    description:
      'There are nearly 5 billion social media users around the world! Yes, you read that correctly. Social media has become one of the most influential methods of communication for people of all…',
    article: 'internal-glossary-doomscrolling',
    path: '/social-media-tools/glossary/doomscrolling',
  },
  {
    name: 'Direct response',
    description:
      'Online advertising campaigns generally either belong to the category of "brand marketing" or "direct response." With brand ads, companies strive to increase brand awareness for their company or keep their…',
    article: 'internal-glossary-direct-response',
    path: '/social-media-tools/glossary/direct-response',
  },
  {
    name: 'Demand generation',
    description:
      "Customers are the lifeblood of any business. Without dedicated people willing to buy and recommend your products and services, your organization can't grow. Unfortunately, acquiring and maintaining consumers isn't always…",
    article: 'internal-glossary-demand-generation',
    path: '/social-media-tools/glossary/demand-generation',
  },
  {
    name: 'Direct Message (DM)',
    description:
      'In the digital world, "DM" usually stands for "Direct Message." A DM is a private mode of communication between social media users. When you send a direct message, only you…',
    article: 'internal-glossary-direct-message-dm',
    path: '/social-media-tools/glossary/direct-message-dm',
  },
  {
    name: 'EdgeRank',
    description:
      'Facebook EdgeRank was another name for the old Facebook algorithm. The Facebook algorithm determines how posts are presented to your potential customers and followers. EdgeRank and the newer algorithms use…',
    article: 'internal-glossary-edgerank',
    path: '/social-media-tools/glossary/edgerank',
  },
  {
    name: 'Emoji',
    description:
      'An emoji is a small icon used to represent an emotion, symbol or object. Emoji commonly appear in communication applications, such as social networking apps and text messaging systems. Over…',
    article: 'internal-glossary-emoji',
    path: '/social-media-tools/glossary/emoji',
  },
  {
    name: 'Engagement rate',
    description:
      'Engagement rates are metrics that track how actively involved with your content your audience is. Engaged consumers interact with brands through "likes" comments and social sharing. The engagement rate is…',
    article: 'internal-glossary-engagement-rate',
    path: '/social-media-tools/glossary/engagement-rate',
  },
  {
    name: 'Facebook retargeting',
    description:
      'Similar to how it functions on other platforms, Facebook retargeting uses a pixel that tracks site visitors, so if they leave without making a purchase, they’ll be presented with related…',
    article: 'internal-glossary-facebook-retargeting',
    path: '/social-media-tools/glossary/facebook-retargeting',
  },
  {
    name: 'Facebook reach',
    description:
      'Facebook reach is a term that defines the number of unique people who viewed your content during a specific time period. However, depending on what the content is, the various…',
    article: 'internal-glossary-facebook-reach',
    path: '/social-media-tools/glossary/facebook-reach',
  },
  {
    name: 'Facebook Lookalike Audience',
    description:
      'Facebook advertisers access numerous ways to reach and connect with their target audience. One option is to utilize Facebook Lookalike Audiences. Lookalike Audiences are a segmentation tool on Facebook useful…',
    article: 'internal-glossary-facebook-lookalike-audience',
    path: '/social-media-tools/glossary/facebook-lookalike-audience',
  },
  {
    name: 'Facebook Note',
    description:
      "The Facebook Note is one of the oldest features that has stood the test of time on Facebook. Notes are a place where individuals and brands share content that's too…",
    article: 'internal-glossary-facebook-note',
    path: '/social-media-tools/glossary/facebook-note',
  },
  {
    name: 'Facebook Share',
    description:
      'The Facebook Share button is a simple and convenient way for people to display content posted by other Facebook users on their social media walls. When someone hits the Facebook…',
    article: 'internal-glossary-facebook-share',
    path: '/social-media-tools/glossary/facebook-share',
  },
  {
    name: 'Facebook Comment',
    description:
      'A Facebook comment lets you respond to a post or picture on Facebook. To comment on a Facebook post, click Comment underneath the post, or click into the white box…',
    article: 'internal-glossary-facebook-comment',
    path: '/social-media-tools/glossary/facebook-comment',
  },
  {
    name: 'Facebook Business Page',
    description:
      'A Facebook business page is a public social media profile designed for commercial organizations. Like a personal Facebook account, Facebook businesses pages allow you to make connections online. Brands use…',
    article: 'internal-glossary-facebook-business-page',
    path: '/social-media-tools/glossary/facebook-business-page',
  },
  {
    name: 'Facebook Messenger',
    description:
      'Facebook Messenger is an instant messenger service owned by Facebook. More than just a mode of consistent communication, Facebook messenger also gives companies ways to deepen their relationships with clients.…',
    article: 'internal-glossary-facebook-messenger',
    path: '/social-media-tools/glossary/facebook-messenger',
  },
  {
    name: 'Facebook Reactions',
    description:
      "Emojis are everywhere in the modern world. We're constantly using emojis to express ourselves and translate our emotions into a digital format. Reactions are Facebook's way of facilitating that emotional…",
    article: 'internal-glossary-facebook-reactions',
    path: '/social-media-tools/glossary/facebook-reactions',
  },
  {
    name: 'Facebook Boost Post',
    description:
      'A Facebook Boost Post is a type of paid advertisement on Facebook promoting an existing post from a business page. Facebook Boost Posts amplify the reach of your content to…',
    article: 'internal-glossary-facebook-boost-post',
    path: '/social-media-tools/glossary/facebook-boost-post',
  },
  {
    name: 'Facebook Ads Manager',
    description:
      'Facebook Ads Manager is an ad management tool to make, edit and analyze paid promotional Facebook campaigns. Facebook recently combined Ads Manager and Power Editor into one platform to make…',
    article: 'internal-glossary-facebook-ads-manager',
    path: '/social-media-tools/glossary/facebook-ads-manager',
  },
  {
    name: 'Facebook Offers',
    description:
      'Facebook Offers is a social media marketing feature that allows brands to promote coupons, discounts and deals to customers, ultimately to help convert more leads. Through enticing deals and discounts, this…',
    article: 'internal-glossary-facebook-offers',
    path: '/social-media-tools/glossary/facebook-offers',
  },
  {
    name: 'Google My Business',
    description:
      'A Google Business Profile (formerly Google My Business) is a free tool for business owners to create a custom profile that appears in Google Search and Maps results. Google receives…',
    article: 'internal-glossary-google-my-business',
    path: '/social-media-tools/glossary/google-my-business',
  },
  {
    name: 'GIFs',
    description:
      'A GIF is a type of digital file created in 1987 in order to reduce the file size of images and short animations. GIFs are widely used because of their…',
    article: 'internal-glossary-gifs',
    path: '/social-media-tools/glossary/gifs',
  },
  {
    name: 'Hashtag',
    description:
      'A hashtag is a word or multi-word phrase which follows the # symbol. Common hashtags include #TBT, or #PicOfTheDay. Hashtags primarily support the categorization of content on social media channels,…',
    article: 'internal-glossary-hashtag',
    path: '/social-media-tools/glossary/hashtag',
  },
  {
    name: 'Instagram Reels',
    description:
      'Instagram announced Reels in August 2020, adding a popular feature from other top social media apps. Reels currently boasts over 500+ million users every day from all walks of life and across the…',
    article: 'internal-glossary-instagram-reels',
    path: '/social-media-tools/glossary/instagram-reels',
  },
  {
    name: 'Instagram Highlight covers',
    description:
      'Instagram Highlight covers are images used to label Instagram Highlights. If you’re unfamiliar with Instagram Highlights, they are essentially Instagram Stories that have been saved to a user’s profile. Highlights…',
    article: 'internal-glossary-instagram-highlight-covers',
    path: '/social-media-tools/glossary/instagram-highlight-covers',
  },
  {
    name: 'Inbound marketing',
    description:
      "Inbound marketing is an advertising methodology focused on creating valuable experiences for customers. The idea is that by supplying your audience with meaningful moments, you'll give them a compelling reason…",
    article: 'internal-glossary-inbound-marketing',
    path: '/social-media-tools/glossary/inbound-marketing',
  },
  {
    name: 'Instagram caption',
    description:
      'Successful Instagram marketing is all about the visuals. The photos that you publish will attract followers, highlight your unique personality and show off the human side of your business. However,…',
    article: 'internal-glossary-instagram-caption',
    path: '/social-media-tools/glossary/instagram-caption',
  },
  {
    name: 'Instagram Comment',
    description:
      'An Instagram Comment is one of the two ways that people engage with the content they see on Instagram. The other option is to simply like the post. Comments are…',
    article: 'internal-glossary-instagram-comment',
    path: '/social-media-tools/glossary/instagram-comment',
  },
  {
    name: 'Influencer',
    description:
      'An influencer is someone in your niche or industry with sway over your target audience. Influencers have specialized knowledge, authority or insight into a specific subject. Their pre-existing presence in…',
    article: 'internal-glossary-influencer',
    path: '/social-media-tools/glossary/influencer',
  },
  {
    name: 'Influencer marketing',
    description:
      'Influencer marketing is a type of social media marketing of promoting and selling products or services through individuals, or influencers, who are capable of driving action from your target audience.…',
    article: 'internal-glossary-influencer-marketing',
    path: '/social-media-tools/glossary/influencer-marketing',
  },
  {
    name: 'Instagram pod',
    description:
      'Instagram is a valuable addition to many social media marketing campaigns - particularly for highly visual brands. However, companies need to know how to hack the Instagram algorithm if they…',
    article: 'internal-glossary-instagram-pod',
    path: '/social-media-tools/glossary/instagram-pod',
  },
  {
    name: 'Key performance indicator (KPI)',
    description:
      "A key performance indicator (KPI) is a main measurement of performance used to track goals and quantify outcomes. How to Measure KPIs Without bench-marketing efforts, it's more difficult to measure…",
    article: 'internal-glossary-key-performance-indicator-kpi',
    path: '/social-media-tools/glossary/key-performance-indicator-kpi',
  },
  {
    name: 'Live streaming',
    description:
      'Every day, the average adult watches more than 5 hours of video. Increasingly, younger generations are replacing standard television channels and cable with streaming services. These solutions allow them to…',
    article: 'internal-glossary-live-streaming',
    path: '/social-media-tools/glossary/live-streaming',
  },
  {
    name: 'LinkedIn Pulse',
    description:
      'LinkedIn Pulse is a publishing platform created by the professional social networking channel, LinkedIn. When Pulse first emerged, it was reserved exclusively for well-known influencers. However, in 2014, LinkedIn decided…',
    article: 'internal-glossary-linkedin-pulse',
    path: '/social-media-tools/glossary/linkedin-pulse',
  },
  {
    name: 'Lead generation',
    description:
      'Lead generation is the process of generating interest from your target customer base to effectively increase future sales. Lead generation is a crucial component in the sales strategy of any…',
    article: 'internal-glossary-lead-generation',
    path: '/social-media-tools/glossary/lead-generation',
  },
  {
    name: 'Multilingual sentiment analysis',
    description:
      'Multilingual sentiment analysis is the AI-driven process of extracting sentiment from data containing several languages. It is achieved through native language machine learning (ML) models built individually for different languages.…',
    article: 'internal-glossary-multilingual-sentiment-analysis',
    path: '/social-media-tools/glossary/multilingual-sentiment-analysis',
  },
  {
    name: 'Machine learning',
    description:
      'Machine learning (ML) is a branch of artificial intelligence (AI) and an essential part of data science. It employs statistical methods to classify or predict patterns in data which can…',
    article: 'internal-glossary-machine-learning',
    path: '/social-media-tools/glossary/machine-learning',
  },
  {
    name: 'Multi-channel attribution',
    description:
      "Multi-channel attribution describes the process of figuring out which avenues of marketing in a customer journey ultimately lead to the conversion. Today's customers interact with companies through a multitude of…",
    article: 'internal-glossary-multi-channel-attribution',
    path: '/social-media-tools/glossary/multi-channel-attribution',
  },
  {
    name: 'Marketing automation',
    description:
      'Marketing automation is the process of using specialist software to automate marketing actions. Many modern marketing departments rely on automation to streamline repetitive tasks like sending emails, texting customers and…',
    article: 'internal-glossary-marketing-automation',
    path: '/social-media-tools/glossary/marketing-automation',
  },
  {
    name: 'Meme',
    description:
      'Memes and social media are perfect for each other. Both concepts are about connecting with people online. While social media is a platform for communication, memes are a way of…',
    article: 'internal-glossary-meme',
    path: '/social-media-tools/glossary/meme',
  },
  {
    name: 'Microblog',
    description:
      'A microblog is a short piece of content designed for quick audience interactions. Microblogging is a combination of instant messaging and content production. With a microblog, you share short messages…',
    article: 'internal-glossary-microblog',
    path: '/social-media-tools/glossary/microblog',
  },
  {
    name: 'Marketing funnel',
    description:
      "A marketing funnel describes your customer's journey with you. From the initial stages when someone learns about your business, to the purchasing stage, marketing funnels map routes to conversion and…",
    article: 'internal-glossary-marketing-funnel',
    path: '/social-media-tools/glossary/marketing-funnel',
  },
  {
    name: 'Midroll',
    description:
      'Midroll is an online advertising term used when advertisers play short ads in the middle of a video. Midroll is a way to monetize longer-form video content displayed on YouTube,…',
    article: 'internal-glossary-midroll',
    path: '/social-media-tools/glossary/midroll',
  },
  {
    name: 'Marketing proposal',
    description:
      'A marketing proposal is a written document used by marketers to communicate a project to a client for consideration, which can include strategy ideas, delivery time frames and estimated costs,…',
    article: 'internal-glossary-marketing-proposal',
    path: '/social-media-tools/glossary/marketing-proposal',
  },
  {
    name: 'Named entity recognition',
    description:
      'Named entity recognition (NER) is a subfield of artificial intelligence (AI) and a natural language processing (NLP) technique. It identifies, tags and categorizes named entities in data such as cities,…',
    article: 'internal-glossary-named-entity-recognition',
    path: '/social-media-tools/glossary/named-entity-recognition',
  },
  {
    name: 'Natural language processing',
    description:
      'Natural language processing (NLP) is a subfield of AI that powers a number of everyday applications such as digital assistants like Siri or Alexa, GPS systems and predictive texts on…',
    article: 'internal-glossary-natural-language-processing',
    path: '/social-media-tools/glossary/natural-language-processing',
  },
  {
    name: 'Net promoter score (NPS)',
    description:
      'Net Promoter Score (NPS) measures whether your customer will refer you to someone new. Since 83% of people say that they trust the recommendations made by their friends and family…',
    article: 'internal-glossary-net-promoter-score-nps',
    path: '/social-media-tools/glossary/net-promoter-score-nps',
  },
  {
    name: 'Niche marketing',
    description:
      'Niche marketing is a highly targeted form of advertisement. With niche marketing, businesses promote their products and services to a small, specific and well-defined audience. Many organizations adopt this strategy…',
    article: 'internal-glossary-niche-marketing',
    path: '/social-media-tools/glossary/niche-marketing',
  },
  {
    name: 'Newsjacking',
    description:
      'Newsjacking is a term coined by David Meerman Scott in his book, "Newsjacking." According to Scott, Newsjacking is the process of adding your thoughts and opinions into breaking news stories. It\'s…',
    article: 'internal-glossary-newsjacking',
    path: '/social-media-tools/glossary/newsjacking',
  },
  {
    name: 'Outbound marketing',
    description:
      'Marketing is a critical component of any business development plan. There are two fundamental kinds of marketing in the world today: inbound and outbound marketing. Although inbound marketing is more…',
    article: 'internal-glossary-outbound-marketing',
    path: '/social-media-tools/glossary/outbound-marketing',
  },
  {
    name: 'Open graph',
    description:
      'Facebook is currently home to over 2.23 billion monthly users. As the most popular social media channel on the web, Facebook offers users a place to catch up with friends,…',
    article: 'internal-glossary-open-graph',
    path: '/social-media-tools/glossary/open-graph',
  },
  {
    name: 'Profile picture',
    description:
      "What is a profile picture? A profile pic is a photo that appears in your online accounts, regardless of whether it's a social media or professional site. Each social media…",
    article: 'internal-glossary-profile-picture',
    path: '/social-media-tools/glossary/profile-picture',
  },
  {
    name: 'Pinterest board',
    description:
      'Pinterest is an image-focused social media platform with over 200 million monthly users. The channel is similar to a digital scrapbook, where users share inspiration, motivational pictures and ideas. More…',
    article: 'internal-glossary-pinterest-board',
    path: '/social-media-tools/glossary/pinterest-board',
  },
  {
    name: 'Personal brand',
    description:
      'Personal branding is the process of defining and promoting what you stand for as an individual. Your personal brand is a culmination of the experiences, skills and values that differentiate…',
    article: 'internal-glossary-personal-brand',
    path: '/social-media-tools/glossary/personal-brand',
  },
  {
    name: 'Return on relationship',
    description:
      'While most are probably familiar with the term return on investment (ROI), return on relationship is a newer concept coined by CMO of Brand Innovators, Ted Rubin. ROR is a…',
    article: 'internal-glossary-return-on-relationship',
    path: '/social-media-tools/glossary/return-on-relationship',
  },
  {
    name: 'Referral traffic',
    description:
      'Referral traffic describes the people who come to your domain from other sites, without searching for you on Google. When someone visits a link from a social network or website…',
    article: 'internal-glossary-referral-traffic',
    path: '/social-media-tools/glossary/referral-traffic',
  },
  {
    name: 'Regram',
    description:
      "Sharing and creating content is a crucial part of social media marketing. A Regram is an effective way to share content on Instagram. Regramming allows brands to distribute another user's…",
    article: 'internal-glossary-regram',
    path: '/social-media-tools/glossary/regram',
  },
  {
    name: 'Repin',
    description:
      'A repin is a post on Pinterest that is saved to one or more of your pinboards. The repin contains the image, title and link to the original source.',
    article: 'internal-glossary-repin',
    path: '/social-media-tools/glossary/repin',
  },
  {
    name: 'Rich Pin',
    description:
      'Rich pins are an SEO-focused aspect of Pinterest. Designed to spread brand awareness, rich pins contribute context to your posts. Using code, rich pins pull metadata from the website pages…',
    article: 'internal-glossary-rich-pin',
    path: '/social-media-tools/glossary/rich-pin',
  },
  {
    name: 'Rizz',
    description:
      "Discover Rizz: A slang term referring to someone's skill in charming or seducing a potential romantic partner, often used to describe their suave and alluring qualities.",
    article: 'internal-glossary-rich-pin',
    path: '/social-media-tools/glossary/rich-pin',
  },
  {
    name: 'Real time marketing',
    description:
      'Real-time marketing or "RTM" is the process of engaging with customers instantly based on breaking news and information. It\'s about maintaining a consistent relationship with your audience with cutting-edge data.…',
    article: 'internal-glossary-real-time-marketing',
    path: '/social-media-tools/glossary/real-time-marketing',
  },
  {
    name: 'Retargeting',
    description:
      'Retargeting, also called remarketing, is an online advertising method of reaching out to previous visitors of your website or app, often by displaying ads or sending emails. Retargeting offers an…',
    article: 'internal-glossary-retargeting',
    path: '/social-media-tools/glossary/retargeting',
  },
  {
    name: 'Semantic search',
    description:
      'Semantic search is an AI-enabled search technique that uses context and intent to understand a query rather than relying on keywords to provide an answer. Semantic search algorithms are used…',
    article: 'internal-glossary-semantic-search',
    path: '/social-media-tools/glossary/semantic-search',
  },
  {
    name: 'Snapchat Memories',
    description:
      'One of Snapchat’s defining features when it launched in 2011, was that pictures and videos called “Snaps” disappeared forever after they were viewed. With 319 million users spending an average…',
    article: 'internal-glossary-snapchat-memories',
    path: '/social-media-tools/glossary/snapchat-memories',
  },
  {
    name: 'Snapchat',
    description:
      'Snapchat is a social media platform and multimedia messaging application that can be accessed via Android and iOS mobile devices. Snapchat users can share pictures and videos with each other…',
    article: 'internal-glossary-snapchat',
    path: '/social-media-tools/glossary/snapchat',
  },
  {
    name: 'Shadow banned',
    description:
      'Have you noticed a sudden drop in your social media engagement? Have you stopped seeing your content in public feeds? If so, you may have been shadow banned.  Social media…',
    article: 'internal-glossary-shadow-banned',
    path: '/social-media-tools/glossary/shadow-banned',
  },
  {
    name: 'Social media etiquette',
    description:
      'Social media etiquette refers to the guidelines that companies and individuals use to preserve their reputation online. As social media channels have evolved to become one of the primary ways…',
    article: 'internal-glossary-social-media-etiquette',
    path: '/social-media-tools/glossary/social-media-etiquette',
  },
  {
    name: 'Social listening',
    description:
      'Social media creates a platform for companies and their consumers to interact seamlessly in the digital age. With social channels, businesses connect with their customers on a deeper level. Being…',
    article: 'internal-glossary-social-listening',
    path: '/social-media-tools/glossary/social-listening',
  },
  {
    name: 'Social monitoring',
    description:
      'There are 2.77 billion social media users around the globe in 2019. Along with pictures of their food and pets, these people are also sharing valuable insights for brands. Every…',
    article: 'internal-glossary-social-monitoring',
    path: '/social-media-tools/glossary/social-monitoring',
  },
  {
    name: 'Social selling',
    description:
      'Social selling is a lead generation strategy designed to help salespeople directly interact with prospects through social platforms. The concept of social selling challenges the typical transaction experience between customers…',
    article: 'internal-glossary-social-selling',
    path: '/social-media-tools/glossary/social-selling',
  },
  {
    name: 'Sentiment analysis',
    description:
      "Sentiment analysis relates to the methods, techniques and processes used to retrieve information about a consumer's perception of a product, service or brand. Tools for sentiment analysis use machine learning…",
    article: 'internal-glossary-sentiment-analysis',
    path: '/social-media-tools/glossary/sentiment-analysis',
  },
  {
    name: 'Social Proof',
    description:
      'Social proof is the psychological concept that people are influenced in their decision making by others, compelling them to act within societal norms or expectations. In the context of marketing,…',
    article: 'internal-glossary-social-proof',
    path: '/social-media-tools/glossary/social-proof',
  },
  {
    name: 'Social media automation',
    description:
      'Social media automation is the process of optimizing social interactions using automated tools. This can include scheduling social posts ahead of time or republishing popular articles. Automating social media publication,…',
    article: 'internal-glossary-social-media-automation',
    path: '/social-media-tools/glossary/social-media-automation',
  },
  {
    name: 'Social media manager',
    description:
      'Social media managers are in charge of representing a company across social channels as the sole voice of the brand. They respond to comments, compile campaigns and create content. These experts…',
    article: 'internal-glossary-social-media-manager',
    path: '/social-media-tools/glossary/social-media-manager',
  },
  {
    name: 'TikTok stories',
    description:
      "What are TikTok stories? TikTok stories are TikTok videos that are deleted after 24 hours. If there's a blue circle around a user's profile picture, then that story is live.…",
    article: 'internal-glossary-tiktok-stories',
    path: '/social-media-tools/glossary/tiktok-stories',
  },
  {
    name: 'Throwback Thursday (TBT)',
    description:
      'Throwback Thursday is a social media trend where brands and content creators share a past photo of themselves or other nostalgic content on Thursday. Throwback Thursday content is usually accompanied…',
    article: 'internal-glossary-throwback-thursday-tbt',
    path: '/social-media-tools/glossary/throwback-thursday-tbt',
  },
  {
    name: 'X (Twitter) poll',
    description:
      'For anyone with an active X (Twitter) presence, polls can be a fun and engaging way to solicit feedback from followers and gain valuable insights regarding the question at hand. While…',
    article: 'internal-glossary-twitter-poll',
    path: '/social-media-tools/glossary/twitter-poll',
  },
  {
    name: 'Target audience',
    description:
      'As outbound marketing gives way to inbound campaigns, companies are discovering the benefits of a target audience. By targeting a specific selection of customers with each of your advertising campaigns,…',
    article: 'internal-glossary-target-audience',
    path: '/social-media-tools/glossary/target-audience',
  },
  {
    name: 'X (Twitter) List',
    description:
      'A List is a curated selection of X (Twitter) accounts. Social media users can either create their own Lists to help organize their marketing strategies or subscribe to those created by…',
    article: 'internal-glossary-twitter-list',
    path: '/social-media-tools/glossary/twitter-list',
  },
  {
    name: 'X (Twitter) handle',
    description:
      'A X (Twitter) handle is the username that appears at the end of your unique X (Twitter) URL. X (Twitter) handles must contain fewer than 15 characters and they appear like so in…',
    article: 'internal-glossary-twitter-handle',
    path: '/social-media-tools/glossary/twitter-handle',
  },
  {
    name: 'X (Twitter) Verification',
    description:
      'Getting verified may be a key tactic in your X (Twitter) marketing strategy. X (Twitter) verification tells your X (Twitter) followers your profile is authentic, trusted and the source of truth for your…',
    article: 'internal-glossary-twitter-verification',
    path: '/social-media-tools/glossary/twitter-verification',
  },
  {
    name: 'UTM code',
    description:
      'A UTM code is a basic snippet of code added to the end of your URL to assist with tracking the performance of content and social media campaigns. There are…',
    article: 'internal-glossary-utm-code',
    path: '/social-media-tools/glossary/utm-code',
  },
  {
    name: 'URL shortener',
    description:
      "An URL shortener is a website that reduces the length of your URL (Uniform Resource Locator). The idea is to minimize the web page address into something that's easier to…",
    article: 'internal-glossary-url-shortener',
    path: '/social-media-tools/glossary/url-shortener',
  },
  {
    name: 'Viral marketing',
    description:
      'All marketing teams create content hoping that their message will spread like wildfire across every consumer touchpoint. Viral marketing is the name given to promotional methods that naturally explode through…',
    article: 'internal-glossary-viral-marketing',
    path: '/social-media-tools/glossary/viral-marketing',
  },
  {
    name: 'YouTube Brand Account',
    description:
      'Not just a video site, but also one of the most successful search engines in the world, YouTube is a valuable place for marketers. As video marketing grows increasingly popular…',
    article: 'internal-glossary-youtube-brand-account',
    path: '/social-media-tools/glossary/youtube-brand-account',
  },
]

module.exports = {
  GLOSSARY,
}
