import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, space, colors, radius } from 'theme'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import BreadCrumbs from 'components/BreadCrumbs'

import imageHeader from 'static/images/integrations/header_picture.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import GridDropdown from '../Faq/GridDropdown'
import GetStartedSection from './GetStartedSection'

import { GLOSSARY } from '../../glossary'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const StyledItemWrapper = styled(Flex)`
  background: ${colors.white};
  box-shadow: 2px 2px 4px rgba(37, 64, 102, 0.08), 0px 2px 4px rgba(118, 139, 168, 0.14);
  border-radius: ${radius.l};
  padding: ${space.l};
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 8px rgb(145 158 171 / 20%);
  }
`

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

const alphabetDataCollected = alphabet.map((letter) => {
  const data = GLOSSARY.filter(({ name }) => name.startsWith(letter))
  return { letter, isActive: data.length !== 0, glossary: data }
})

const Tools = () => {
  return (
    <App fullHeader>
      <SEO
        title="Social Media Glossary"
        description="Social media and digital marketing changes fast and comes with lots of terminology. Our Social Media Glossary has an up to date list of the most current definitions of popular social media terms."
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb="xxl"
              pt={{ mobile: 0, desktop: 'm' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex
                      alignItems="center"
                      display={{ mobile: 'none', mobileLarge: 'flex' }}
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    >
                      <BreadCrumbs
                        data={[
                          { label: 'Vista Social', path: '/' },
                          { label: 'Free Tools', path: '/social-media-tools/' },
                          { label: 'Glossary', path: '/social-media-tools/glossary/' },
                        ]}
                      />
                    </Flex>
                    <Flex
                      mt="l"
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Social Media{' '}
                        <H1
                          as="span"
                          fontSize="4xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          Glossary
                        </H1>
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      Search through our list of the most popular social media marketing glossary terms below. Don’t get
                      left in the dark with industry terminology. This is your one-stop shop to study up on the most
                      popular terms.
                    </H2>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="617px"
                  alt="Powerful social media integrations"
                />
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="row" width="100%" justifyContent="space-between">
            {alphabetDataCollected.map(({ letter, isActive }) => (
              <Text
                fontSize="xl"
                fontWeight="bold"
                p="10"
                as={isActive ? 'a' : 'span'}
                href={isActive ? `#${letter}` : ''}
                color={isActive ? 'primary' : 'secondaryText'}
              >
                {letter}
              </Text>
            ))}
          </Flex>
        </Container>
      </Wrapper>

      {alphabetDataCollected.map(({ letter, isActive, glossary }, index) => (
        <Wrapper id={letter} bg={index % 2 !== 0 ? COLOR_CONSTANTS.DAISY : COLOR_CONSTANTS.WHITE}>
          <Container
            pl={{ mobile: 'l', tablet: 'l' }}
            pr={{ mobile: 'l', tablet: 'l' }}
            pb={isActive ? { mobile: 'l', tablet: 'xxl' } : 'l'}
            position="relative"
            maxWidth={CONTAINER_MAX_WIDTH}
            width="100%"
            height="100%"
          >
            <H1 textAlign="left" py="l">
              {letter}
            </H1>

            {isActive ? (
              <Grid
                width="100%"
                gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2,1fr)', desktop: 'repeat(3, 1fr)' }}
                gridGap="l"
              >
                {glossary
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(({ name, description, path = '#' }) => (
                    <StyledItemWrapper
                      flexDirection="column"
                      key={name}
                      maxWidth={{ mobile: '100%', tablet: '445px' }}
                      width="100%"
                      onClick={() => {
                        window.open(path, '_self')
                      }}
                    >
                      <Flex flexDirection="column">
                        <Flex alignItems="center">
                          <Flex flexDirection="column" width="100%">
                            <Flex justifyContent="space-between" alignItems="center" width="100%">
                              <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                                {name}
                              </Text>
                              <Text as="a" href={path}>
                                <LocalImage src={imageDoubleArrowRightBlue} />
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                        <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                          {description}
                        </Text>
                      </Flex>
                    </StyledItemWrapper>
                  ))}
              </Grid>
            ) : (
              <Flex mb="m">
                <Text>No results for this...yet!</Text>
              </Flex>
            )}
          </Container>
        </Wrapper>
      ))}

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H1>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.all.slice(0, 3)} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default Tools
